import React from "react";
import Seo from "../components/Seo";
import Content from "../views/GooglePolicy/Content";
import Hero from "../views/GooglePolicy/Hero";

const LegalsPage = () => {
  return (
    <React.Fragment>
      <Seo title="Politique de confidentialité" />
      <Hero />
      <Content />
    </React.Fragment>
  );
};

export default LegalsPage;
