import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { GOOGLE_POLICY_CONTENT_SECTION } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const Content = () => {
  return (
    <StyledSectionWrapper sectionId={GOOGLE_POLICY_CONTENT_SECTION}>
      <Container>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Utilisation de vos informations depuis un compte Google
          </Typography>
          <Typography variant="body2" gutterBottom>
            La société Innovance, éditrice de la solution Innovance Agenda, peut
            requérir la connexion à votre compte Google pour vous permettre
            d’ajouter un calendrier issu de Google Calendar™ au sein de son
            application d'Agenda.
            <br />
            <br />
            Dans ce cadre, Innovance souhaite accéder aux données concernant vos
            calendriers et vos événements. L'utilisation de ces données se fait
            en lecture seule, aucune modification ne sera apportée à celles-ci
            par l’application Innovance Agenda.
            <br />
            <br />
            L'utilisation et le transfert d'informations reçues des API de
            Google par l’application Innovance Agenda respecte{" "}
            <a
              target="_blank"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            >
              la politique des données utilisateur des services API de Google
            </a>
            , y compris les exigences relatives à l'utilisation limitée.
          </Typography>
        </div>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Comment vos données sont utilisées ?
          </Typography>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/EU8Wek33XwY?si=5J5cmXeO4WXFRVTC"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Content;
